import React, { useState } from "react";
import Icon from "../Icon";

export default function PasswordField({
  label,
  width,
  height,
  placeholder = "Password",
  name = "password",
  disabled,
  value,
  message,
  error,
  onInputChange,
}) {
  const [passwordShown, setPasswordShown] = useState(true);

  const onIconClick = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div className={` flex flex-col gap-y-1 relative ${width}`}>
      {label ? <label className=" label">{label}</label> : null}
      <div className=" flex flex-row"></div>
      <input
        type={!passwordShown ? "text" : "password"}
        onChange={onInputChange}
        value={value}
        name={name}
        placeholder={placeholder}
        className={!error ? ` input  ${height}  ` : ` errorInput ${height} `}
      />
      {passwordShown ? (
        <Icon name="icon-eye-close" label={label} onClick={onIconClick} />
      ) : (
        <Icon name="icon-eye-open" label={label} onClick={onIconClick} />
      )}
      {error ? <Icon name="icon-warning" /> : null}
      {error ? <h5 className=" errorMessage">{message}</h5> : null}
    </div>
  );
}
