import React from "react";
import LTMobileLoginForm from "./LTMobileLoginForm";

export default function LTMobileLogin({
  onChange,
  formFields,
  onBackClick,
  onButtonClick,
}) {
  return (
    <div className="bg-Body flex flex-row  custom2:hidden">
      <div className={`w-full min-h-screen`}>
        <div className="flex flex-row justify-center pt-4 items-center">
          {process.env.NEXT_PUBLIC_THEME === "LT" ? (
            <>
              <img
                src="/Images/logo-circle.webp"
                width={"36px"}
                height={"36px"}
                className="  rounded-full"
              />
              <img
                src="/Images/wordmark-luckytruck.webp"
                width={"131px"}
                height={"32px"}
              />
            </>
          ) : (
            <img src="/Images/flexport/Logo-flexport.webp" width={"150px"} />
          )}
        </div>
        <LTMobileLoginForm
          onChange={onChange}
          formFields={formFields}
          onBackClick={onBackClick}
          onButtonClick={onButtonClick}
        />
      </div>
    </div>
  );
}
