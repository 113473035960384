import React from "react";
import LTDesktopLoginForm from "./LTDesktopLoginForm";

export default function LTDesktopLogin({ onChange, formFields, onBackClick, onButtonClick }) {
  return (
    <div className="ltLogin-grid  xs:hidden ">
      <div className=" ">
        <LTDesktopLoginForm
          onChange={onChange}
          formFields={formFields}
          onBackClick={onBackClick}
          onButtonClick={onButtonClick}
        />
      </div>
      <div className=" flex  flex-col items-center justify-center ">
        {process.env.NEXT_PUBLIC_THEME === "LT" ? (
          <div className="py-8">
            <img src={"/Images/agentMain.png"} style={{ maxWidth: "fit-content", maxHeight: "90vh" }} />
          </div>
        ) : (
          <>
            <img src={"/Images/flexport/bigTruck.webp"} />
            <img width={"300px"} src="/Images/flexport/Logo-flexport.webp" />
          </>
        )}
      </div>
    </div>
  );
}
