import React from "react";
import Icon from "../../Icon";
import { useRouter } from "next/router";
import IconButton from "../../IconButton";

export default function LTMobileLoginForm({ onChange, formFields, onBackClick, onButtonClick }) {
  const router = useRouter();
  const [passwordShown, setPasswordShown] = React.useState(true);

  const onIconClick = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div className="flex flex-col space-y-6 mt-8 px-10">
      <div className="flex gap-4 cursor-pointer" onClick={onBackClick}>
        <Icon height={15} name="icon-backarrow" />
        <h3 className="text-blue5 -mt-1">Back to site</h3>
      </div>

      <img
        src={
          process.env.NEXT_PUBLIC_THEME === "LT" ? "/Images/icon-semitruck.webp" : "/Images/flexport/semi-truck.webp"
        }
        width={130}
        className="self-center"
      />
      <h3 className={`text-gray3 text-center font-hkBold text-textMobile`}>
        Welcome back <span className=" text-bgActiveButton">&nbsp;Team!</span>
      </h3>
      <h3 className="text-textMobile text-center font-hkMedium text-gray6 opacity-80">
        Sign in to support your customers! (Go LuckTruck!🤘🏻)
      </h3>

      <div
        className={`flex flex-col border-custom ${
          formFields.email.error || formFields.password.error
            ? "border-errorColor rounded-md"
            : "border-borderColor rounded-md"
        } gap-y-0 relative`}
      >
        <input
          type="text"
          placeholder="Email"
          className={"inputMobile"}
          value={formFields.email.value}
          onChange={onChange}
          name="email"
        />
        <input
          type={!passwordShown ? "text" : "password"}
          placeholder="Password"
          name="password"
          value={formFields.password.value}
          onChange={onChange}
          className={"passwordMobile"}
        />
        {passwordShown ? (
          <Icon name="icon-eye-close-mobile" onClick={onIconClick} />
        ) : (
          <Icon name="icon-eye-open-mobile" onClick={onIconClick} />
        )}
        {formFields.email.error || formFields.password.error ? <Icon name="icon-warning-mobile" /> : null}
      </div>
      {formFields.email.error && !formFields.password.error ? (
        <h5 className="errorMessage">{formFields.email.message}</h5>
      ) : null}
      {formFields.password.error && !formFields.email.error ? (
        <h5 className="errorMessage">{formFields.password.message}</h5>
      ) : null}
      {formFields.email.error && formFields.password.error ? (
        <h5 className="errorMessage">{"Email and Password are required"}</h5>
      ) : null}
      <h3
        className="text-center mt-4 font-interMedium text-base text-createAcc cursor-pointer"
        onClick={() => router.push(process.env.NEXT_PUBLIC_LT_TEAM_FORGET_PASWORD)}
      >
        Forgot your password?
      </h3>
      <div className="flex flex-row items-center2 w-full">
        <IconButton
          title={"CONTINUE"}
          width="w-full"
          onButtonClick={onButtonClick}
          // onButtonClick={buttonClickedVerificationCode}
          height="h-14"
        />
      </div>
      {formFields.mainError ? <h5 className=" errorMessage">{formFields.mainErrorMessage}</h5> : null}
    </div>
  );
}
