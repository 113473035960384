import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";
const PageL1 = forwardRef(({ children, title = "", ...rest }, ref) => {
  return (
    <div ref={ref} className="bg-white" {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});
PageL1.displayName = "Page";

export default PageL1;
