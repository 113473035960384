import React from "react";
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "./LT_Loader.json";
import styles from "../../styles/Loader.module.css";

export default function Loader() {
  const options = {
    animationData: groovyWalkAnimation,
    autoplay: true,
  };

  const { View } = useLottie(options, {
    width: 150,
    height: 150,
    textAlign: "center",
  });
  return <div className={styles.loading}>{View}</div>;
}
